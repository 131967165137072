<template>
  <div id="edit-epg-content">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("epg.AddContEpg") }} </h1>
        <h5 class="text-primary">
          {{ $t("editContent.subtitle") }} EPG
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-row style="align-items: center">
          <b-col>
            <b-button
              variant="danger"
              @click="goToBackPage"
            >
              {{
                $t("dataGeneric.goBack")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      style="height:740px"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        class="mb-3 p-1"
        style="justify-content: space-around;"
        @on-complete="formSubmitted"
      >
        <!-- Información del contenido tab -->
        <tab-content
          :title="$t('epg.dataEpgContent')"
          :before-change="validationFormInfo"
          class="mb-2"
        >
          <!-- <validation-observer ref="infoRules" tag="form"> -->

          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group
                    :label="$t('dataGeneric.name') + ' *'"
                    :state="validationContext.errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="name"
                      v-model="nameContent"
                      type="text"
                      maxlength="150"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('dataGeneric.name')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ fixMin(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="image"
                  rules="url"
                >
                  <b-form-group
                    :label="$t('dataGeneric.urlImage')"
                    :state="validationContext.errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="image"
                      v-model="imageContent"
                      type="text"
                      maxlength="1000"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('dataGeneric.urlImage')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ fixMin(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    id="description"
                    v-model="descriptionContent"
                    rows="3"
                    :placeholder="$t('dataGeneric.description')"
                  />
                </b-form-group>
              </b-col>

              <b-col class="embed-responsive embed-responsive-16by9">
                <b-img
                  class="mb-3 embed-responsive-item"
                  style="max-width:100%; height:50%;"
                  :src="imageContent == null
                    ? require('@/assets/images/backend/placeholder.png')
                    : imageContent
                  "
                  fluid
                  block
                  rounded
                  @click="focus"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          :title="$t('epg.timeDuration')"
          :before-change="validationFormTime"
        >
          <validation-observer
            ref="timeRules"
            tag="form"
          >
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('epg.startDate')"
                >
                  <flat-pickr
                    id="startDate"
                    v-model="timeContent"
                    class="form-control"
                    :placeholder="$t('epg.startDate')"
                    :config="{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S'
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group :label="$t('contents.Duration')">
                  <b-form-input
                    id="duration"
                    v-model="durationContent"
                    type="number"
                    :min="0"
                    :placeholder="$t('epg.contentDuration')"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('epg.dataLinked')"
          :before-change="validationFormData"
        >
          <validation-observer
            ref="dataRules"
            tag="form"
          >
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('epg.ageClasific')"
                  class="mb-2 mr-1"
                >
                  <b-form-select
                    id="clasification"
                    v-model="clasificationContent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <b-form-select-option :value="null">
                      {{ $t('epg.ageClasific') }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="s in allClasification"
                      :key="s.node.id"
                      :value="s.node.id"
                    >
                      <strong :style="'color:' + s.node.color + ';'">
                        {{ s.node.initial }} - {{ s.node.description }}
                      </strong>
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('epg.genre')"
                  class="mb-2 mr-1"
                >
                  <b-form-select
                    id="genre"
                    v-model="genreContent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allGenre"
                    label="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col cols="9">
                <div @click="$refs['my-modal'].show()">
                  <b-form-input
                    id="contenido"
                    v-model="vinculoContentName"
                    disabled
                    type="text"
                    :placeholder="$t('epg.linkedContent')"
                    autocomplete="new-password"
                  />
                </div>
              </b-col>
              <b-col>
                <b-button
                  variant="info"
                  @click="$refs['my-modal'].show()"
                >
                  {{ $t('select') }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('contents.selectContent')"
    >
      <div class="d-block text-center">
        <content-selector
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['my-modal'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, utf8ToB64, showToast } from '@/store/functions'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import ContentSelector from '@/views/common/ContentSelector.vue'

// import "flatpickr/dist/themes/material_blue.css";
export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BImg,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    ContentSelector,
    BOverlay,
  },
  data() {
    return {
      required,
      allGenre: [],
      allClasification: [],
      nameContent: null,
      imageContent: null,
      descriptionContent: null,
      timeContent: null,
      durationContent: 0,
      clasificationContent: null,
      genreContent: null,
      userData: getUserData(),
      vinculoContentName: null,
      vinculoContentId: null,
      show: false,
      imageData: null,
      idEpg: null,
    }
  },
  mounted() {
    try {
      this.idEpg = this.$route.params.epg
      this.timeContent = this.fechaFormato(this.$route.params.time)
    } catch (err) {
      console.log(err)
    }

    this.clasification()
    this.genre()
  },
  methods: {
    fixMin(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('purchases.nameFormValid')
      }
      if (keyName === 'min') {
        return this.$t('purchases.nameFormThreeCaract')
      }
      if (keyName === 'url') {
        return this.$t('tabUser.urlValid')
      }
      return ''
    },
    focus() {
      document.getElementById('image').focus()
    },
    formSubmitted() {
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation($description: String,$startDate: DateTime!) {
           createEpgContent(input:{
             epg:"${utf8ToB64(`id:${this.idEpg}`)}"
             ${this.clasificationContent != null
    ? `clasification:"${utf8ToB64(
      `id:${this.clasificationContent}`,
    )}",`
    : ''
}
             ${this.genreContent != null
    ? `genre:"${utf8ToB64(`id:${this.genreContent}`)}",`
    : ''
}
             ${this.vinculoContentId != null
    ? `content:"${utf8ToB64(
      `id:${this.vinculoContentId}`,
    )}",`
    : ''
}
             name:"${this.nameContent}",
            description: $description,
            ${this.imageContent != null
    ? `image:"${this.imageContent}",`
    : ''
}
          startDate: $startDate,
          duration:${this.durationContent}
        
         }){
            epgContent{
              name
            }
          }
        }
        `

      data.append('query', query)
      const variables = {
        description: this.descriptionContent,
        startDate: this.fechaFormato(this.timeContent),
      }
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)

          showToast(this.$t('success'), 1, this)
          this.show = false
          this.$router.push(`/contents/epg/contents/${this.idEpg}`)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },

    editarContenido(data) {
      this.nameContent = data.name
      this.imageContent = data.image
      this.descriptionContent = data.description
      this.timeContent = data.startDate
      this.durationContent = data.duration
      this.clasificationContent = data?.clasification?.id
      this.genreContent = data?.genre?.id

      this.vinculoContentName = data.content.name
      this.vinculoContentId = data.content.id
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{
            allClasification {
              edges {
                node {
                 id
                type
                initial
                description
                color
                clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive
                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    goToBackPage() {
      window.history.back()
    },

    SelectContentId(data) {
      this.vinculoContentId = data
      this.$refs['my-modal'].hide()
    },
    SelectContentUrl(data) {
      this.vinculoContentName = data
      this.$refs['my-modal'].hide()
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormTime() {
      return new Promise((resolve, reject) => {
        this.$refs.timeRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormData() {
      return new Promise((resolve, reject) => {
        this.$refs.dataRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
